@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* Prevent a broken image picture when the image is not found: */
    .searchresultimage {
        text-indent: -5000px;
        height: auto;
        width: auto;
        object-fit: cover;
    }

    /* https://www.frontendhero.dev/tutorial/blurred-paywall-area/ */
    .fade-out {
        position: relative;
    }

    .fade-out:after {
        content: "";
        height: 80%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, transparent, hsl(var(--background)));
    }

    .dark .fade-out:after {
        background: linear-gradient(to bottom, transparent, hsl(var(--background)));
    }

    .blur-text {
        filter: blur(2.5px);
        user-select: none;
        pointer-events: none;
    }

    /* For hidden input fields: */
    .honey {
        display: none;
    }

    /* for the horizontal scroll drag nightmare */
    .select-none {
        user-select: none;
    }

    /* For MD autolinks */
    .anchor {
        position: relative;
        left: 4px;
        text-decoration: none !important;
        font-size: 0.8rem;
        color: hsl(var(--muted-foreground));
        vertical-align: middle;
    }

    .h-screen-dashboard {
        height: 92vh;
    }

    /* clipboard copy protection */
    .no-select {
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    /* Bell animation */
    @keyframes bell-ring {
        0% {
            transform: rotate(0);
        }
        10% {
            transform: rotate(15deg);
        }
        20% {
            transform: rotate(-15deg);
        }
        30% {
            transform: rotate(10deg);
        }
        40% {
            transform: rotate(-10deg);
        }
        50% {
            transform: rotate(5deg);
        }
        60% {
            transform: rotate(-5deg);
        }
        70% {
            transform: rotate(2deg);
        }
        80% {
            transform: rotate(-2deg);
        }
        90% {
            transform: rotate(1deg);
        }
        100% {
            transform: rotate(0);
        }
    }

    .animate-bell {
        animation: bell-ring 1s ease-in-out;
        transform-origin: top;
    }

    @keyframes wobble {
        0% {
            transform: translateX(0);
        }
        15% {
            transform: translateX(-3px) rotate(-2deg);
        }
        30% {
            transform: translateX(2px) rotate(1.5deg);
        }
        45% {
            transform: translateX(-1.5px) rotate(-1deg);
        }
        60% {
            transform: translateX(1px) rotate(0.5deg);
        }
        75% {
            transform: translateX(-0.5px) rotate(-0.25deg);
        }
        100% {
            transform: translateX(0);
        }
    }

    .animate-wobble {
        animation: wobble 1s ease-in-out 4s infinite;
    }

    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;
        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;
        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;
        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;
        --radius: 0.5rem;
        --sidebar-background: 0 0% 98%;
        --sidebar-foreground: 240 5.3% 26.1%;
        --sidebar-primary: 240 5.9% 10%;
        --sidebar-primary-foreground: 0 0% 98%;
        --sidebar-accent: 240 4.8% 95.9%;
        --sidebar-accent-foreground: 240 5.9% 10%;
        --sidebar-border: 220 13% 91%;
        --sidebar-ring: 217.2 91.2% 59.8%;
        --chart-1: 142.1 76.2% 36.3%;
        --chart-2: 48 96% 53%;
        --chart-3: 0 84.2% 60.2%;
        --chart-4: 262.1 83.3% 57.8%;
        --chart-5: 221.2 83.2% 53.3%;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;
        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;
        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;
        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;
        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;
        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;
        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;
        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;
        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
        --sidebar-background: 240 5.9% 10%;
        --sidebar-foreground: 240 4.8% 95.9%;
        --sidebar-primary: 224.3 76.3% 48%;
        --sidebar-primary-foreground: 0 0% 100%;
        --sidebar-accent: 240 3.7% 15.9%;
        --sidebar-accent-foreground: 240 4.8% 95.9%;
        --sidebar-border: 240 3.7% 15.9%;
        --sidebar-ring: 217.2 91.2% 59.8%;
        --chart-1: 220 70% 50%;
        --chart-5: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-2: 340 75% 55%;
    }

    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
}

/* Anchor link styles */
.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6 {
    scroll-margin-top: 6rem;
    position: relative;
}

.prose .anchor {
    color: var(--muted-foreground);
    text-decoration: none;
    margin-left: 0.5rem;
    opacity: 0;
}

.prose *:hover > .anchor,
.prose *:focus > .anchor {
    opacity: 1;
}
